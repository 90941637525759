@import '../../styles/includes.scss';

.ObjectEntry {
    $root: &;
    background-color: $colorWhite;
    font-family: $fontBrand;
    text-decoration: none;
    overflow: hidden;
    transition: background-color 0.2s, box-shadow 0.2s;

    &:not(:last-of-type) {
        border-bottom: 0.1rem solid $colorGray200;
    }

    &:hover {
        @include media(ml) {
            box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.10);
        }
    }

    &--Active {
        border-bottom: 0.4rem solid $colorPink !important;
        background-color: $colorGray40;

        
        // @include media(m) {
            
        // }
        // @include media(ml) {
        //     border-bottom: 0.4rem solid $colorPink !important;
        // }
    }

    &--Expanded {
        background-color: $colorWhite;
    }


    &__Container {
        display: flex;
        cursor: pointer;
        position: relative;
        padding: 1.6rem 0px;

        #{$root}--Expanded & {
            display: block;
        }

        @include media(ml) {
            padding: 2.4rem 2.4rem 2.4rem 0;
        }

    }

    &__ImageContainer {
        width: 40%;
        transition: transform $transition;
        float: left;
        margin: 0 1.2rem 0 0.8rem;

        #{$root}--Expanded & {
            width: 33.5%;
        }

        @include media(m) {
            margin: 0 2rem;
        }
    }

    &__DateContainer {
        position: absolute;
        bottom: 2px;
        left: 10px;
        z-index: 1;

        @include media(m) {
            bottom: 10px;
        }
    }

    &__Date {
        display: block;
        @extend %body-2;
        line-height: 18px;
        font-size: 12px;

        &--Day {
            font-size: 14px;
            font-weight: bold;

            @include media(m) {
                font-size: 20px;
            }
        }

        @include media(m) {
            font-size: 16px;
            line-height: 20px;
        }

    }

    &__DateBackground {
        z-index: 1;
        position: absolute;
        background-color: $colorBlue50;
        transition: transform 0.5s;
        width: 115px;
        height: 166px;
        bottom: -84px;
        left: -70px;
        transform: rotate(-40deg);

        @include media(m) {
            bottom: -64px;
            left: -62px;
            transform: rotate(-40deg);
        }

        &--Blue50 {
            background-color: $colorBlue50;
        }

        &--Green50 {
            background-color: $colorGreen50;
        }

        &--Purple50 {
            background-color: $colorPurple50;
        }

        &--Orange50 {
            background-color: $colorOrange50;
        }

    }

    &__ContentContainer {
        width: 75%;

        &--NoImage {
            width: 100%;
        }

        #{$root}--Expanded & {
            width: auto;
        }
    }

    &__Image {
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__Caption {
        @extend %body-4;
        padding: 3px;
        word-wrap: break-word
    }

    &__Content {
        margin-right: 1.2rem;
        box-sizing: border-box;
        @include media(ml) {
            margin-right: 2.4rem;
        }
    }

    &__Title {
        color: $colorBlack;
        font-weight: bold;
        letter-spacing: 0.02rem;
        line-height: 2rem;
        font-size: 1.4rem;
        font-family: $fontBrand;
        text-decoration: none;
        display: block;
        margin-bottom: 0.7rem;

        @include media(ml) {
            letter-spacing: 0.04rem;
            line-height: 3.2rem;
            font-size: 1.9rem;
            margin-bottom: 0.6rem;
        }
    }

    &__Label {
        color: $colorBlack800;
        font-family: $fontBrand;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.26rem;
        font-size: 1.2rem;
        margin-bottom: 1.1rem;

        @include media(ml) {
            font-size: 1.4rem;
            margin-bottom: 1.4rem;
        }
    }

    &__ButtonContainer {
        display: flex;
        flex-direction: column;
        flex-basis: 4.8rem;
        margin-left: auto;
        align-items: flex-end;
        overflow: visible;
        float: right;
        margin-right: -16px;

        @include media(m) {
            margin-right: 0;
        }
    }

    &__RemoveButton {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        color: $colorGray500;
        font-family: $fontBrand;
        text-decoration: underline;
        font-size: 1.4rem;
        margin-bottom: 12px;
    }

    &__RemoveButtonIcon {
        display: flex;

        & svg {
            height: 16px;
        }

        &:hover svg {
            color: $colorPink;
        }

    }


    &__Button {
        border: none;
        background: transparent;
        padding-right: 0;

        @include media(m) {
            margin-bottom: 0.5rem;
        }

        &:hover svg {
            color: $colorPink;
        }

        &--BookMarkActive svg {
            // color: $colorPink;
        }
    }

    &__ButtonIcon {
        width: 4.8rem;

        @include media(m) {
            width: 2.4rem;
        }
    }

    &__ReadMoreButton {
        display: none;

        @include media(m) {
            display: initial;
        }

        #{$root}--Expanded & {
            display: initial;
        }

        svg {
            transform: rotate(90deg);
            transition: transform 0.4s ease-in-out;
            color: $colorPink;
        }

        &--Expanded {
            svg {
                transform: rotate(-90deg);
                transition: transform 0.4s ease-in-out;
            }
        }
    }

    &__Text {
        color: $colorBlack800;
        font-family: $fontBrand;
        font-size: 1.6rem;
        line-height: 2.64rem;
        height: 5.5rem;
        overflow: hidden;

        @include media(ml) {
            font-size: 1.8rem;
            line-height: 2.88rem;
            margin-bottom: 1.9rem;
        }

        @include media(l) {
            height: 8.2rem;
        }

        &--Expanded {
            overflow: visible;
            height: auto;
        }

        &.ObjectEntry__Text--Expanded {
            height: auto;
        }

        h4 {
            @extend %h4;
            margin-bottom: $spacingL;
        }

        p {
            margin-bottom: $spacingL;
        }

        a {
            @extend %links;
        }
    }

    &__MassInfo {
        margin-top: 12px;
    }

    &__InfoBoxes {
        display: flex;
        flex-direction: column;

        @include media(ml) {
            padding: 0 1.6rem;
        }

        @include media(l) {
            flex-direction: row;
        }
    }

    &__MobileClickHandler {
        position: absolute;
        top: 0;
        left: 0;
        right: 50px;
        bottom: 0;
        z-index: 1;

        #{$root}--Expanded & {
            display: none;
        }

        @include media(m) {
            display: none;
        }
    }

}
