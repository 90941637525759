@import 'styles/includes.scss';

.LocalsTip {
    $root: &;
    font-family: $fontBrand;
    display: flex;
    flex-direction: column;
    align-items: center;
    @extend %container;

    &__Container {
        max-width: $maxWidthText;
        margin: 80px 0;
    }

    &__IntroContainer {
        position: relative;
        z-index: 1;
        margin-bottom: 10rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 79px;

        &:after {
            left: 0;
            content: ' ';
            border-top: 112px solid $colorPurple50;
            border-left: 95px solid transparent;
            width: 0;
            position: absolute;
            top: 0;
            transform: rotate(180deg);
            z-index: -1;

            #{$root}--Green & {
                border-top-color: $colorGreen50;
            }
            #{$root}--Blue & {
                border-top-color: $colorBlue50;
            }
            #{$root}--Orange & {
                border-top-color: $colorOrange50;
            }

            @include media(ml) {
                border-top-width: 141px;
                border-left: 120px solid transparent;
                left: $spacingXl * 3 - 50px;
            }

            @include media(l) {
                top: calc(50% - 92px);
                left: $spacingXl * 3 - 50px;
            }
        }
    }

    &__TextContainer {
        position: relative;
        top: 50px;
    }

    &__TitleContainer {
        margin-left: 35px;

        @include media(ml) {
            margin-left: 34px;
        }
    }

    &__Preamble {
        font-size: 1.4rem;
        letter-spacing: 2.6px;
        text-transform: uppercase;
        color: $colorBlack800;
        max-width: 489px;

        @include media(ml) {
            font-size: 1.6rem;
            letter-spacing: 2.6px;
        }
    }

    &__Title {
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.31;
        letter-spacing: 0.4px;
        color: $colorBlack800;
        max-width: 489px;
        margin-bottom: 42px;

        @include media(ml) {
            font-size: 3.5rem;
            line-height: 1.31;
            letter-spacing: 0.8px;
        }
    }

    &__Text {
        font-size: 1.6rem;
        line-height: 1.6;
        color: $colorBlack800;


        @include media(ml) {
            font-size: 1.8rem;
            line-height: 1.6;
            max-width: 489px;
            width: 100%;
            margin-left: 0;
        }
    }

    &__ImageContainer {
        height: 0;
    }

    &__ImageFigure {
        display: flex;
        justify-content: flex-end;
    }

    &__Image {
        max-width: 99px;
        height: 120px;
        object-fit: cover;

        @include media(ml) {
            max-width: 120px;
            height: 144px;
        }
    }

    &__Caption {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: 0.2px;
        text-align: right;
        color: $colorBlack800;
        max-width: 177px;
        word-break: break-word;
    }

}
