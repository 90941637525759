@import '../../styles/includes.scss';

.MassInfo {
    background-color: $colorGray40;
    padding: 1.2rem;
    margin-bottom: 1.2rem;

    & p:last-of-type {
        margin-bottom: 0;
    }
}
