@import '../../styles/includes.scss';

.Label {
    font-family: $fontBrand;
    font-size: 1.4rem;
    letter-spacing: 0.02rem;
    border-radius: 4rem;
    padding: 0.9rem 1.8rem;
    text-align: center;
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    transition: 300ms border;

    &:hover {
        border: black 0.1rem solid;
    }

    &--Purple {
        background-color: $colorPurple50;
        border: $colorPurple50 0.1rem solid;
    }

    &--Green {
        background-color: $colorGreen50;
        border: $colorGreen50 0.1rem solid;
    }

    &--Blue {
        background-color: $colorBlue50;
        border: $colorBlue50 0.1rem solid;
    }

    &--Orange {
        background-color: $colorOrange50;
        border: $colorOrange50 0.1rem solid;
    }

    &--Active {
        background: $colorBlack800;
        color: $colorWhite;
    }
}
