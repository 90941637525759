@import 'styles/includes.scss';

.ListOccurrences {
    color: $colorBlack800;
    padding: 1.6rem 2rem;
    border-left: 4px solid $colorPink600;

    @include media(ml) {
        padding: 1.6rem 2rem;
    }

    &__Title {
        @extend %h4;
        font-size: 1.7rem;
        font-size-adjust: none;
        margin-bottom: $spacingL;
        color: $colorBlack;

        @include media(l) {
            font-size: 1.9rem;
        }
    }

    &__Label {
        @extend %labels;
        font-size: 1.6rem;
        font-size-adjust: none;
        letter-spacing: initial;
        text-transform: none;
        margin-bottom: 16px;
        @include media(l) {
            font-size: 1.8rem;
        }
    }

    &__More {
        svg {
            transform: rotate(90deg);
            color: $colorPink;
        }

        > span {
            display: flex;
        }
    }

    &__List {
        margin-bottom: 16px;

        &--NoButton {
            margin-bottom: 0;
        }
    }

    &__List,
    &__ListContent {
        display: block;
        width: 100%;
    }

    &__Row {
        display: flex;

        &:last-child {
            .ListOccurrences__RowItem {
                .ListOccurrences__List--NoButton & {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__RowItem {
        width: 50%;
        max-width: 240px;
        font-size: 1.6rem;
        font-size-adjust: none;
        margin-bottom: 8px;
        @include media(l) {
            font-size: 1.8rem;
        }
    }
}
