@import '../../styles/includes.scss';
@import '../../styles/keyframes.scss';

.ObjectList {
    &__HeaderContainer {
        margin: 40px 0;
    }

    &__Header {
        margin: 0 0 19px;
        max-width: 831px;
        font-size: 35px;
        font-weight: bold;
        line-height: 1.31;
        letter-spacing: 0.8px;
        color: $colorBlack800;
    }

    &__Text {
        max-width: 618px;
        text-shadow: 0 2px 20px rgba(0, 0, 0, 0.21);
        font-size: 16px;
        line-height: 1.65;
        color: $colorBlack800;
    }

    &__ObjectListContainer {
        display: flex;
        flex-direction: column-reverse;
        animation: fadeIn 1s;
        overflow: hidden;

        @include media(ml) {
            flex-direction: row;
        }
    }

    &__Objects {
        flex-basis: 100%;
        background-color: $colorWhite;
        padding-top: 40px;

        @include media(m) {
            flex-basis: calc(809 / 1440 * 100%);
            height: 90rem;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        }

        @include media(ml) {
            flex-basis: calc(889 / 1440 * 100%);
        }
    }

    &__ObjectContainer {
        width: 100%;
        height: 50rem;
        padding-top: 10px;
        padding-bottom: $spacingL;
        padding-left: 2px;
        overflow-y: scroll;

        @include media(m) {
            height: auto;
            padding-right: 2rem;
            overflow-y: hidden;
        }
    }

    &__LabelTitle {
        font-family: $fontBrand;
        flex-basis: 100%;
        display: block;
        font-size: 1.8rem;
        line-height: 1.5;
        margin-bottom: 1.7rem;
    }

    &__LabelContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__MapContainer {
        padding: 0;
        flex-basis: 0;
        height: 90rem;

        @include media(m) {
            margin: 0;
            flex-basis: calc(631 / 1440 * 100%);
            min-width: calc(631 / 1440 * 100%);
        }

        @include media(ml) {
            flex-basis: calc(551 / 1440 * 100%);
            min-width: calc(551 / 1440 * 100%);
        }
    }

    &__MapDummyText {
        display: none;

        @include media(m) {
            display: block;
            font-family: $fontBrand;
            font-size: 4rem;
            text-align: center;
            margin-top: 5rem;
        }
    }

    &__animation-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in;
    }

    &__animation-leave {
        opacity: 1;
    }

    &__animation-exit {
        opacity: 1;
    }

    &__animation-exit-active {
        opacity: 0.01;
        transition: opacity 400ms ease-in;
    }
}
